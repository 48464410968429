@font-face {
  font-family: 'DroidSans', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Droid+Sans');
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body,
body * {
  font-family: "DroidSans", sans-serif;
  font-stretch: normal;
  font-style: normal;
  font-size: 1.6rem;
}

:root {
  /* Common styles */
  --color-white: #ffffff;
  --color-black: #000000;
  --color-primary-green: rgb(46, 186, 198);
  --color-primary-purple: rgb(182, 80, 158);

  /* Background colors */
  --bg-color-primary: rgb(43, 45, 60);
  --bg-color-secondary: rgb(56, 61, 81);
  --bg-color-white: #ffffff;

  /* Typography colors */
  --text-color-primary: rgb(56, 61, 81);
  --text-color-secondary: #ffffff;
  --text-size-lg: 30px;
  --text-size-md: 14px;
  --text-size-sm: 30px;

  --color-input: #252a4a;
  --color-text-footer: #42434e;
  --color-border-main: #eaeaea;
  --color-primary: #ff481c;
  --color-secondary: #d3d3d3;
}

.custom-scroll {
  overflow: auto;
  overflow-x: hidden;
}

.custom-scroll::-webkit-scrollbar {
  width: 7px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: var(--color-primary);
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #e53d52;
}

.custom-scroll::-webkit-scrollbar-track {
  background: rgb(56, 61, 81) !important;
}

.custom-scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}
